import { Chip } from "@mui/material";
import { Datagrid, List, Pagination, TextField } from "react-admin";
import { AdvancedListActions } from "../shared/AdvancedFilterForm";

const QuickFilter = ({
  label,
}: {
  label: string;
  source?: string;
  defaultValue?: string;
}) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const AdminPagination = () => (
  <div style={{ width: "100%", display: "flex" }}>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
  </div>
);

export const AdminList = () => (
  <List
    // filter={{ withDeleted: true, isAdmin: true }}
    // sort={{ field: "createdAt", order: "DESC" }}
    actions={<AdvancedListActions />}
    pagination={<AdminPagination />}
  >
    <div style={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "90%" }}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="email" />
        <TextField label="First Name" source="userProfile.firstName" />
        <TextField label="Last Name" source="userProfile.lastName" />
      </Datagrid>
    </div>
  </List>
);
