"use client";

import { cookieDomain } from "@/utils";
import { COOKIE_EXPIRY_DAYS } from "@/utils/constants";
import { setCookie } from "cookies-next";
import { emailLoginRequest } from "./auth.server";
import restClientWrapper from "./wrappers/rest.client.wrapper";
export const getAuthActions = () => {
  const emailLogin = async (variables: {
    email: string;
    password: string;
    recaptchaToken: string;
  }) => {
    const _emailLogin = restClientWrapper(emailLoginRequest);

    const data = await _emailLogin(variables);

    setCookie("adminAccessToken", data.accessToken, {
      domain: cookieDomain(),
      path: "/",
      expires: new Date(Date.now() + COOKIE_EXPIRY_DAYS),
    });
  };

  return {
    emailLogin,
  };
};
