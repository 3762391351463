import { getAllApplicantsQuery, getOneCreatorQuery } from "./applicants.server";
import {
  GetAllApplicantsQueryVariables,
  GetOneCreatorQueryVariables,
} from "./types";
import clientWrapper from "./wrappers/client.wrapper";

export const getApplicantsActions = () => {
  const getAllApplicants = (variables: GetAllApplicantsQueryVariables) => {
    return getAllApplicantsQuery(variables);
  };

  const getOneCreator = (variables: GetOneCreatorQueryVariables) => {
    return getOneCreatorQuery(variables);
  };
  return {
    getAllApplicants: clientWrapper(getAllApplicants),
    getOneCreator: clientWrapper(getOneCreator),
  };
};
