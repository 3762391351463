import { GetOneParams } from "react-admin";
import {
  deletePostCommentMutation,
  deletePostMutation,
  getPostQuery,
  getPostsQuery,
  restorePostCommentMutation,
  restorePostMutation,
  updatePostsMutation,
} from "./posts.server";
import { GetPostsQueryTypeVariables } from "./types";
import clientWrapper from "./wrappers/client.wrapper";

export const getPostsActions = () => {
  const getPosts = (variables: GetPostsQueryTypeVariables) => {
    return getPostsQuery(variables);
  };

  const getPost = (variables: GetOneParams) => {
    return getPostQuery(variables);
  };

  const deletePost = (variables: { id: string }) => {
    return deletePostMutation(variables);
  };

  const restorePost = (variables: { id: string }) => {
    return restorePostMutation(variables);
  };

  const deletePostComment = (variables: { id: string; postId: string }) => {
    return deletePostCommentMutation(variables);
  };

  const restorePostComment = (variables: { id: string; postId: string }) => {
    return restorePostCommentMutation(variables);
  };

  const updatePosts = (variables: {
    input: {
      posts: { id: string; recommendedOrder: number }[];
    };
  }) => {
    return updatePostsMutation(variables);
  };
  return {
    getPost: clientWrapper(getPost),
    getPosts: clientWrapper(getPosts),
    deletePost: clientWrapper(deletePost),
    restorePost: clientWrapper(restorePost),
    deletePostComment: clientWrapper(deletePostComment),
    restorePostComment: clientWrapper(restorePostComment),
    updatePosts: clientWrapper(updatePosts),
  };
};
