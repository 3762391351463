import {
  getCreatorProfileQuery,
  getUserForCreatorsDataProviderQuery,
  getUsersForCreatorsDataProviderQuery,
  updateCreatorProfileMutation,
  validateCreatorProfileMutation,
} from "./creators.server";
import {
  AdminUpdateCreatorProfileInput,
  GetUsersQueryVariables,
} from "./types";
import clientWrapper from "./wrappers/client.wrapper";

export const getCreatorsActions = () => {
  const getUserForCreatorsDataProvider = (variables: {
    adminGetUserId: string;
  }) => {
    return getUserForCreatorsDataProviderQuery(variables);
  };

  const getUsersForCreatorsDataProvider = (
    variables: GetUsersQueryVariables
  ) => {
    return getUsersForCreatorsDataProviderQuery(variables);
  };

  const getCreatorProfile = (variables: { adminGetUserId: string }) => {
    return getCreatorProfileQuery(variables);
  };

  const updateCreatorProfile = (variables: {
    userId: string;
    input: AdminUpdateCreatorProfileInput;
  }) => {
    return updateCreatorProfileMutation(variables);
  };

  const validateCreatorProfile = (variables: {
    creatorId: string;
    action: "approve" | "reject";
  }) => {
    return validateCreatorProfileMutation(variables);
  };

  return {
    getUserForCreatorsDataProvider: clientWrapper(
      getUserForCreatorsDataProvider
    ),
    getUsersForCreatorsDataProvider: clientWrapper(
      getUsersForCreatorsDataProvider
    ),
    getCreatorProfile: clientWrapper(getCreatorProfile),
    updateCreatorProfile: clientWrapper(updateCreatorProfile),
    validateCreatorProfile: clientWrapper(validateCreatorProfile),
  };
};
