import { getAuthActions } from "@/server-actions";
import Cookies from "universal-cookie";

const cookies = new Cookies();
interface LoginObject {
  username: string;
  password: string;
  recaptchaToken: string;
}

const { emailLogin } = getAuthActions();

const authProvider = {
  login: loginProvider,

  checkAuth: async () => {
    // const { executeRecaptcha } = useReCaptcha();
    // const recaptchaToken = await executeRecaptcha("login");
    if (isLoggedOutRecently()) {
      return Promise.reject(new Error("User logged out recently!"));
    }
    // Validate accessToken is present and the user is an admin
    const accessToken = cookies.get("adminAccessToken");
    if (accessToken) {
      // Check if the user has the necessary role or permission to access the admin
      const user = decodeJwt(accessToken);
      if (user?.isAdmin) {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  },
  getPermissions: () => {
    return Promise.resolve();
  },
  logout: () => {
    cookies.remove("adminAccessToken");
    cookies.remove("adminRefreshToken");
    localStorage.setItem("logout", new Date().valueOf().toString());
    return Promise.resolve();
  },
  checkError: errorProvider,
};

async function errorProvider(error: any) {
  const status = error.status;
  if (status === 401 || status === 403) {
    cookies.remove("adminAccessToken");
    cookies.remove("adminRefreshToken");
    return Promise.reject();
  }
  // other error code (404, 500, etc): no need to log out
  return Promise.resolve();
}

async function loginProvider(loginObject: LoginObject) {
  if (isLoggedOutRecently()) {
    return; // do not allow login
  }
  try {
    await emailLogin({
      email: loginObject.username,
      password: loginObject.password,
      recaptchaToken: loginObject.recaptchaToken,
    });
  } catch (error) {
    throw error;
  }
}

function decodeJwt(token: any) {
  if (!token) {
    return;
  }
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

function isLoggedOutRecently() {
  const logoutTimeStamp = localStorage.getItem("logout");
  if (logoutTimeStamp) {
    const nowTimeStamp = new Date().valueOf();
    const diff = nowTimeStamp - parseInt(logoutTimeStamp);
    if (diff < 5000) {
      return true;
    }
  }

  return false;
}

export default authProvider;
