import {
  createNoteMutation,
  deleteNoteMutation,
  getNotesQuery,
  updateNoteMutation,
} from "./notes.server";
import clientWrapper from "./wrappers/client.wrapper";

export const getNotesActions = () => {
  const getNotes = (variables: { input: { userId: string } }) => {
    return getNotesQuery(variables);
  };

  const createNote = (variables: {
    input: { userId: string; content: string };
  }) => {
    return createNoteMutation(variables);
  };

  const updateNote = (variables: {
    input: { content: string };
    id: string;
  }) => {
    return updateNoteMutation(variables);
  };

  const deleteNote = (variables: { input: { id: string } }) => {
    return deleteNoteMutation(variables);
  };

  return {
    getNotes: clientWrapper(getNotes),
    createNote: clientWrapper(createNote),
    updateNote: clientWrapper(updateNote),
    deleteNote: clientWrapper(deleteNote),
  };
};
