import {
  GetAdminLivestreamsVariables,
  UpdateLiveStreamVariables,
} from "./livestream.types";
import {
  endAdminLiveStreamMutation,
  getAdminLiveStreamQuery,
  getAdminLiveStreamsQuery,
  updateAdminLiveStreamMutation,
} from "./livestreams.server";

import clientWrapper from "./wrappers/client.wrapper";

export const getAdminLivestreamActions = () => {
  const getAdminLiveStreams = (variables: GetAdminLivestreamsVariables) => {
    return getAdminLiveStreamsQuery(variables);
  };
  const getAdminLiveStream = (variables: { adminGetLivestreamId: string }) => {
    return getAdminLiveStreamQuery(variables);
  };
  const endLiveStream = (variables: {
    input: { creatorId: string; id: string };
  }) => {
    return endAdminLiveStreamMutation(variables);
  };
  const updateLiveStream = (variables: UpdateLiveStreamVariables) => {
    return updateAdminLiveStreamMutation(variables);
  };

  return {
    getAdminLivestreams: clientWrapper(getAdminLiveStreams),
    getAdminLivestream: clientWrapper(getAdminLiveStream),
    endLiveStream: clientWrapper(endLiveStream),
    updateLiveStream: clientWrapper(updateLiveStream),
  };
};
