import {
  getUserForAdminsDataProviderMutation,
  getUsersForAdminsDataProviderMutation,
  updateAdminAccessMutation,
} from "./admins.server";
import { GetUsersQueryVariables } from "./types";
import clientWrapper from "./wrappers/client.wrapper";

export const getAdminsActions = () => {
  const getUserForAdminsDataProvider = (variables: {
    adminGetUserId: string;
  }) => {
    return getUserForAdminsDataProviderMutation(variables);
  };

  const getUsersForAdminsDataProvider = (variables: GetUsersQueryVariables) => {
    return getUsersForAdminsDataProviderMutation(variables);
  };

  const updateAdminAccess = (variables: {
    input: { isAdmin: boolean };
    userId: string;
  }) => {
    return updateAdminAccessMutation(variables);
  };

  return {
    getUserForAdminsDataProvider: clientWrapper(getUserForAdminsDataProvider),
    getUsersForAdminsDataProvider: clientWrapper(getUsersForAdminsDataProvider),
    updateAdminAccess: clientWrapper(updateAdminAccess),
  };
};
