import ContentFilter from "@mui/icons-material/FilterList";
import { Box, Button, Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import {
  SelectInput,
  TextInput,
  TopToolbar,
  useListContext,
} from "react-admin";
import { FormProvider, useForm } from "react-hook-form";

const AdvancedFilterForm = () => {
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  const [showForm, setShowForm] = useState(!!displayedFilters.main);

  useEffect(() => {
    setShowForm(!!displayedFilters.main);
  }, [displayedFilters.main]);

  const form = useForm({
    defaultValues: filterValues,
  });
  if (!displayedFilters.main) return null;

  const onSubmit = (values: any) => {
    const trimmedValues = {
      ...values,
      query: values.query?.trim(),
    };
    if (Object.keys(trimmedValues).length > 0) {
      setFilters(trimmedValues, null);
    } else {
      hideFilter("main");
      setShowForm(false);
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
    setShowForm(false);
  };

  return (
    <Collapse in={showForm}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="row" alignItems="flex-end" mb={1}>
            <Box component="span" display="flex" flexDirection="row" mr={2}>
              <TextInput
                resettable
                helperText={false}
                source="query"
                label="Search"
                sx={{ minWidth: "200px", marginRight: "10px" }}
              />

              <SelectInput
                source="property"
                helperText={false}
                defaultValue="username"
                choices={[
                  { id: "id", name: "Id" },
                  { id: "email", name: "Email" },
                  { id: "username", name: "Username" },
                  { id: "firstName", name: "First name" },
                  { id: "lastName", name: "Last name" },
                  { id: "fullName", name: "Full name" },
                ]}
                sx={{ minWidth: "200px" }}
              />
            </Box>
            <Box component="span" mr={2} mb={1.5}>
              <Button variant="outlined" color="primary" type="submit">
                Filter
              </Button>
            </Box>
            <Box component="span" mb={1.5}>
              <Button variant="outlined" onClick={resetFilter}>
                Close
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Collapse>
  );
};

export const PostFilterButton = () => {
  const { showFilter } = useListContext();
  return (
    <Button
      size="small"
      color="primary"
      onClick={() => showFilter("main", null)}
      sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      startIcon={<ContentFilter />}
    >
      Filter
    </Button>
  );
};

export const AdvancedListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <PostFilterButton />
    </TopToolbar>
    <AdvancedFilterForm />
  </Box>
);

export default AdvancedFilterForm;
