/* eslint-disable react-hooks/exhaustive-deps */
import { getUsersActions } from "@/server-actions";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import {
  Alert,
  Avatar,
  Button,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  BooleanInput,
  Edit,
  ImageField,
  Link,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useEditContext,
} from "react-admin";
import EditFieldModal from "../shared/EditFieldModal/EditFieldModal";
import NotesModal from "../shared/Notes/NotesModal";
import { PaymentList } from "./PaymentList";
import RemoveCommentsConfirmationModal from "./RemoveCommentsConfirmationModal";

export const UserEdit = () => (
  <Edit redirect={false}>
    <UserEditForm />
  </Edit>
);

const UserEditForm = (props: ToolbarProps) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const { record } = useEditContext<User>();
  const [user, setUser] = useState<User>(
    record || {
      id: "",
      email: "",
      userProfile: null,
      deletedAt: null,
      roles: [],
      isAdmin: false,
      creatorProfile: null,
      lastLoginAt: null,
      lastSeenAt: null,
    }
  );
  const [showEmailConfirmationModal, setShowEmailConfirmationModal] =
    useState(false);
  const [showUserNameModal, setShowUserNameModal] = useState(false);
  const [
    showRemoveCommentsConfirmationModal,
    setShowRemoveCommentsConfirmationModal,
  ] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(record?.email);
  const [alertMessage, setAlertMessage] = useState<{
    content: string;
    type: "error" | "success";
  }>({ content: "", type: "error" });

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const {
    createCreatorProfile: createCreatorProfileMutation,
    restoreUser: restoreUserMutation,
    softDeleteUser: softDeleteUserMutation,
    softDeleteUserComments,
    updateUser,
    updateUserProfileAdmin,
  } = getUsersActions();

  useEffect(() => {
    if (record?.deletedAt) {
      setIsDeleted(true);
    }
  }, [record]);

  const updateUserName = async (username: string) => {
    try {
      const result = await updateUserProfileAdmin({
        userId: user.id,
        input: { username },
      });
      setUser({
        ...user,
        userProfile: {
          ...user.userProfile,
          username: result.adminUpdateUserProfile.username,
        },
      });
      setShowUserNameModal(false);
    } catch (error) {
      throw error;
    }
  };

  const changeEmail = async (userId: string, email: string) => {
    try {
      const result = await updateUser({
        adminUpdateUserId: userId,
        input: { email },
      });
      setCurrentEmail(result?.adminUpdateUser.email);
      setShowEmailConfirmationModal(false);
    } catch (error) {
      throw error;
    }
  };

  const deleteAvatar = async (id: string) => {
    try {
      const result = await updateUserProfileAdmin({
        userId: id,
        input: { avatarUrl: null },
      });

      setUser({
        ...user,
        userProfile: result?.adminUpdateUserProfile,
      });
    } catch (error) {
      throw error;
    }
  };

  const deleteUserBio = async (id: string) => {
    try {
      const result = await updateUserProfileAdmin({
        userId: id,
        input: { bio: null },
      });

      setUser({
        ...user,
        userProfile: result?.adminUpdateUserProfile,
      });
    } catch (error) {
      throw error;
    }
  };

  const softDeleteUser: any = async (id: string) => {
    try {
      const result = await softDeleteUserMutation({ id });
      setIsDeleted(Boolean(result?.adminSoftDeleteUser));
    } catch (error) {
      throw error;
    }
  };

  const restoreUser: any = async (id: string) => {
    try {
      const result = await restoreUserMutation({ id });
      setIsDeleted(!result?.adminRestoreUser);
    } catch (error) {
      throw error;
    }
  };

  const createCreatorProfile = async (id: string) => {
    try {
      const result = await createCreatorProfileMutation({ userId: id });

      setUser({
        ...user,
        creatorProfile: result?.adminCreateCreatorProfile,
      });
    } catch (error) {
      throw error;
    }
  };

  const massSoftDeleteUserComments = async (id: string) => {
    try {
      const result = await softDeleteUserComments({ id });
      setAlertMessage({
        content: `Successfully removed all previously undeleted comment for user ${user?.userProfile?.username} -- total removed: ${result?.adminSoftDeleteUserComments}`,
        type: "success",
      });
    } catch (error) {
      throw error;
    }
    setShowRemoveCommentsConfirmationModal(false);
  };
  return (
    <>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            {!user?.userProfile?.avatarUrl ? (
              <Stack direction="row" justifyContent="flex-start" pl={4}>
                <Avatar sx={{ width: 56, height: 56 }} />
              </Stack>
            ) : (
              <ImageField
                label={"Profile pic"}
                source="userProfile.avatarUrl"
                title="profile picture"
              />
            )}
          </Grid>
          <Grid item xs={12} md={8} alignItems="center">
            <TextInput
              data-testid={`user-id-input-ad`}
              label={"User Id"}
              source="id"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4} alignItems="center">
            <Stack mb={3}>
              <div>
                <Button
                  data-testid={`delete-avatar-button-ad`}
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={() => deleteAvatar(user?.id!)}
                  style={{ marginTop: "10px" }}
                  disabled={!user?.userProfile?.avatarUrl}
                >
                  Delete Avatar
                </Button>
              </div>
            </Stack>
            <Stack spacing={2}>
              <div>
                <strong>Email:</strong> {currentEmail}
              </div>
            </Stack>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={() => setShowEmailConfirmationModal(true)}
              style={{ marginTop: "10px" }}
            >
              Change Email
            </Button>
            <EditFieldModal
              sourceInput="email"
              open={showEmailConfirmationModal}
              onClose={() => setShowEmailConfirmationModal(false)}
              onCallbackUpdate={(field: string) =>
                changeEmail(record!.id, field.trim().toLowerCase())
              }
              title={`Are you sure you want to change email ${currentEmail} ?`}
            />
          </Grid>
          <Grid item xs={12} md={4} alignItems="center">
            <TextInput
              data-testid={`first-name-input-ad`}
              label={"First Name"}
              source="userProfile.firstName"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4} alignItems="center">
            <TextInput
              data-testid={`last-name-input-ad`}
              label={"Last Name"}
              source="userProfile.lastName"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={4} alignItems="center">
            <div>
              <strong>User Name:</strong> {user?.userProfile?.username}
            </div>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={() => setShowUserNameModal(true)}
              style={{ marginTop: "10px" }}
            >
              Change User Name
            </Button>
            <EditFieldModal
              sourceInput="userProfile.username"
              open={showUserNameModal}
              onClose={() => setShowUserNameModal(false)}
              onCallbackUpdate={(field: string) => updateUserName(field)}
              title={`Are you sure you want to change the username ${user?.userProfile?.username} ?`}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} alignItems="center">
            <Stack mb={3}>
              <div>
                <div style={{ marginTop: "10px" }}>
                  <strong>Bio:</strong> {user?.userProfile?.bio}
                </div>
                <Button
                  data-testid={`delete-bio-button-ad`}
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={() => deleteUserBio(user!.id)}
                  style={{ marginTop: "10px" }}
                  disabled={!user?.userProfile?.bio}
                >
                  Delete User Bio
                </Button>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={3} alignItems="center">
            <Stack mb={3}>
              <div>
                <div style={{ marginTop: "10px" }}>
                  <strong>Notes:</strong>
                </div>
                <Button
                  data-testid={`delete-bio-button-ad`}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => setOpenNotesModal(true)}
                  endIcon={<EditIcon />}
                  style={{ marginTop: "10px" }}
                >
                  Notes
                </Button>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "10px" }}>
              <strong>Subscriptions:</strong>
            </div>
            <Link
              to={`subscriptions?isCreator=${!!record?.creatorProfile}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                data-testid={`subscription-button-adm`}
                variant="outlined"
                color="primary"
                size="large"
                endIcon={<ReceiptLongIcon />}
                style={{ marginTop: "10px" }}
              >
                Subscriptions
              </Button>
            </Link>
          </Grid>
        </Grid>
        <BooleanInput
          data-testid={`is-admin-input-ad`}
          label={"Is User an Admin?"}
          source="isAdmin"
        />
        <Grid>
          <Button
            data-testid={`delete-user-button-ad`}
            disabled={isDeleted}
            variant="contained"
            color="error"
            size="large"
            onClick={() => softDeleteUser(user!.id)}
            style={{
              marginRight: "5px",
              marginLeft: "5px",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            {isDeleted ? "Deleted" : "Delete"}
          </Button>
          <Button
            data-testid={`restore-user-button-ad`}
            disabled={!isDeleted}
            variant="contained"
            color="success"
            size="large"
            onClick={() => restoreUser(user!.id)}
            style={{
              marginRight: "5px",
              marginLeft: "5px",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            {isDeleted ? "Restore" : "Restored"}
          </Button>
        </Grid>
        <Stack mt={2}>
          {user.creatorProfile && (
            <>
              <Typography>
                <strong>Creator Profile Applied at: </strong>
                {user.creatorProfile?.appliedAt
                  ? new Date(user.creatorProfile?.appliedAt).toDateString()
                  : ""}
              </Typography>
              <Typography>
                <strong>Creator Profile Accepted at: </strong>
                {user.creatorProfile?.acceptedAt
                  ? new Date(user.creatorProfile?.acceptedAt).toDateString()
                  : ""}
              </Typography>
            </>
          )}
          <Typography>
            <strong>Last Login at: </strong>
            {user.lastLoginAt ? new Date(user.lastLoginAt).toDateString() : ""}
          </Typography>
          <Typography>
            <strong>Last Seen at: </strong>
            {user.lastSeenAt ? new Date(user.lastSeenAt).toDateString() : ""}
          </Typography>
          {user.creatorProfile && (
            <Button
              variant="contained"
              size="large"
              color="info"
              onClick={() =>
                window.location.replace(
                  `${location.origin}/#/creators/${user?.id}`
                )
              }
              style={{
                marginRight: "5px",
                marginLeft: "5px",
                marginTop: "30px",
                marginBottom: "5px",
              }}
            >
              Go to Creator Profile
            </Button>
          )}
        </Stack>
        {!user?.creatorProfile && (
          <Button
            variant="contained"
            size="large"
            color="success"
            onClick={() => createCreatorProfile(user!.id)}
            style={{
              marginRight: "5px",
              marginLeft: "5px",
              marginTop: "30px",
              marginBottom: "5px",
            }}
          >
            Create Creator Application
          </Button>
        )}
        <Grid item md={4} alignItems="center">
          <Button
            variant="contained"
            color="error"
            size="large"
            onClick={() => setShowRemoveCommentsConfirmationModal(true)}
            style={{
              marginRight: "5px",
              marginLeft: "5px",
              marginTop: "60px",
              marginBottom: "5px",
            }}
          >
            Remove All User Comments
          </Button>
          <Snackbar
            open={Boolean(alertMessage.content)}
            autoHideDuration={10000}
            onClose={() => setAlertMessage({ content: "", type: "success" })}
          >
            <Alert
              onClose={() => setAlertMessage({ content: "", type: "success" })}
              severity={alertMessage.type}
              sx={{ width: "100%" }}
            >
              {alertMessage.content}
            </Alert>
          </Snackbar>
          <RemoveCommentsConfirmationModal
            open={showRemoveCommentsConfirmationModal}
            onClose={() => setShowRemoveCommentsConfirmationModal(false)}
            onConfirm={() => massSoftDeleteUserComments(user!.id)}
            username={user.userProfile?.username!}
            id={user.id!}
          />
          <NotesModal
            open={openNotesModal}
            onClose={() => setOpenNotesModal(false)}
            userId={record?.id ? record.id : ""}
          />
        </Grid>
      </SimpleForm>
      {!user.creatorProfile && <PaymentList userId={user.id} />}
    </>
  );
};

export interface User {
  id: string;
  email: string;
  userProfile?: UserProfile | null;
  createdAt?: string | null;
  deletedAt: string | null;
  roles: string[];
  isAdmin: boolean;
  creatorProfile?: CreatorProfile | null;
  lastLoginAt: string | null;
  lastSeenAt: string | null;
}

interface UserProfile {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  bio?: string;
  avatarUrl?: string;
  bannerUrl?: string;
  websiteUrl?: string;
}

interface CreatorProfile {
  appliedAt?: string;
  acceptedAt?: string;
}
