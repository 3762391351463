// in src/components/AdminApp.jsx
"use client"; // only needed if you choose App Router
import {
  adminsDataProviders,
  applicantDataProvider,
  blastsDataProviders,
  creatorsDataProviders,
  postsDataProvider,
  usersDataProviders,
} from "@/utils";
import authProvider from "@/utils/authProvider";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MessageIcon from "@mui/icons-material/Message";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import {
  Admin,
  Resource,
  combineDataProviders,
  defaultLightTheme,
} from "react-admin";

import { adminsLiveStreamsDataProviders } from "@/utils";

import { Route } from "react-router-dom";
import CustomLoginForm from "./Login";
import { AdminCreate } from "./admins/AdminCreate";
import { AdminEdit } from "./admins/AdminEdit";
import { AdminList } from "./admins/AdminList";
import { BlastsList } from "./blasts/BlastList";
import { CreatorEdit } from "./creators/CreatorEdit";
import { CreatorList } from "./creators/CreatorList";

import { LivestreamEdit } from "./livestreams/LivestreamEdit";
import { LiveStreamsLists } from "./livestreams/LivestreamLists";
import { PostEdit } from "./posts/EditPost";
import { PostList } from "./posts/PostList";
import { RecommendedPostsLists } from "./recommended/RecommendedPostsLists";
import Subscriptions from "./subscriptions/Subscriptions";
import { UserEdit } from "./users/UserEdit";
import { UserList } from "./users/UserList";
const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "posts":
      return postsDataProvider;
    case "recommended":
      return postsDataProvider;
    case "creators":
      return creatorsDataProviders;
    case "applicants":
      return applicantDataProvider;
    case "users":
      return usersDataProviders;
    case "admins":
      return adminsDataProviders;
    case "blasts":
      return blastsDataProviders;
    case "livestreams":
      return adminsLiveStreamsDataProviders;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});
const AdminApp = () => (
  <Admin
    loginPage={CustomLoginForm}
    title={"Admin"}
    dataProvider={dataProvider}
    authProvider={authProvider}
    theme={defaultLightTheme}
  >
    <Resource
      name="creators"
      edit={CreatorEdit}
      list={CreatorList}
      recordRepresentation="creators"
      icon={StarIcon}
    >
      <Route path=":userId/subscriptions" element={<Subscriptions />} />
    </Resource>

    <Resource
      name="users"
      edit={UserEdit}
      list={UserList}
      recordRepresentation="Users"
      icon={PersonIcon}
    >
      <Route path=":userId/subscriptions" element={<Subscriptions />} />
    </Resource>

    <Resource
      name="posts"
      edit={PostEdit}
      list={PostList}
      recordRepresentation="Posts"
      icon={DescriptionIcon}
    />

    {/* applications are currently handled in creators */}
    {/* <Resource
      name="applicants"
      edit={CreatorEdit}
      list={ApplicantList}
      recordRepresentation="applicants"
      icon={AssignmentIndIcon}
    /> */}

    <Resource
      name="recommended"
      recordRepresentation={"Recommended Posts"}
      edit={PostEdit}
      list={RecommendedPostsLists}
    />
    {/* <Resource
      name="categories"
      recordRepresentation={"Categories"}
      // edit={}
      list={CategoriesLists}
    /> */}

    <Resource
      name="admins"
      edit={AdminEdit}
      create={AdminCreate}
      list={AdminList}
      recordRepresentation="admins"
      icon={GroupAddIcon}
    />
    <Resource
      name="blasts"
      list={BlastsList}
      recordRepresentation="blasts"
      icon={MessageIcon}
    />

    <Resource
      name="livestreams"
      edit={LivestreamEdit}
      recordRepresentation="livestreams"
      list={LiveStreamsLists}
      icon={OnlinePredictionIcon}
    />
  </Admin>
);

export default AdminApp;
