import { GetOneParams } from "react-admin";
import {
  deleteMessageBlastMutation,
  getMessageBlastsQuery,
  getOneUserQuery,
} from "./blasts.server";
import { GetBlastsQueryVariables } from "./types";
import clientWrapper from "./wrappers/client.wrapper";

export const getBlastsActions = () => {
  const getMessageBlasts = (variables: GetBlastsQueryVariables) => {
    return getMessageBlastsQuery(variables);
  };

  const getOneUser = (variables: GetOneParams) => {
    return getOneUserQuery(variables);
  };

  const deleteMessageBlast = (variables: { creatorId: string; id: string }) => {
    return deleteMessageBlastMutation(variables);
  };

  return {
    getMessageBlasts: clientWrapper(getMessageBlasts),
    getOneUser: clientWrapper(getOneUser),
    deleteMessageBlast: clientWrapper(deleteMessageBlast),
  };
};
