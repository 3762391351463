import {
  AdminGetPaymentsInput,
  AdminUpdateUserInput,
  AdminUpdateUserProfileInput,
  GetUsersQueryVariables,
} from "./types";
import {
  createCreatorProfileMutation,
  deleteUserMutation,
  getFanPaymentsQuery,
  getUserForUsersDataProviderQuery,
  getUsersForUsersDataProviderQuery,
  restoreUserMutation,
  softDeleteUserCommentsMutation,
  softDeleteUserMutation,
  updateUserMutation,
  updateUserProfileAdminMutation,
} from "./users.server";
import clientWrapper from "./wrappers/client.wrapper";

export const getUsersActions = () => {
  const getUsersForUsersDataProvider = (variables: GetUsersQueryVariables) => {
    return getUsersForUsersDataProviderQuery(variables);
  };

  const getUserForUsersDataProvider = (variables: {
    adminGetUserId: string;
  }) => {
    return getUserForUsersDataProviderQuery(variables);
  };

  const deleteUser = (variables: { id: string; deleteUser: boolean }) => {
    return deleteUserMutation(variables);
  };

  const softDeleteUser = (variables: { id: string }) => {
    return softDeleteUserMutation(variables);
  };

  const restoreUser = (variables: { id: string }) => {
    return restoreUserMutation(variables);
  };

  const softDeleteUserComments = (variables: { id: string }) => {
    return softDeleteUserCommentsMutation(variables);
  };

  const createCreatorProfile = (variables: { userId: string }) => {
    return createCreatorProfileMutation(variables);
  };

  const updateUserProfileAdmin = (variables: {
    userId: string;
    input: AdminUpdateUserProfileInput;
  }) => {
    return updateUserProfileAdminMutation(variables);
  };

  const updateUser = (variables: {
    adminUpdateUserId: string;
    input: AdminUpdateUserInput;
  }) => {
    return updateUserMutation(variables);
  };

  const getFanPayments = (variables: {
    input: AdminGetPaymentsInput;
    userId: string;
  }) => {
    return getFanPaymentsQuery(variables);
  };

  return {
    getUsersForUsersDataProvider: clientWrapper(getUsersForUsersDataProvider),
    getUserForUsersDataProvider: clientWrapper(getUserForUsersDataProvider),
    softDeleteUser: clientWrapper(softDeleteUser),
    deleteUser: clientWrapper(deleteUser),
    restoreUser: clientWrapper(restoreUser),
    softDeleteUserComments: clientWrapper(softDeleteUserComments),
    createCreatorProfile: clientWrapper(createCreatorProfile),
    updateUserProfileAdmin: clientWrapper(updateUserProfileAdmin),
    updateUser: clientWrapper(updateUser),
    getFanPayments: clientWrapper(getFanPayments),
  };
};
