import {
  cancelSubscriptionMutation,
  getSubscriptionsQuery,
} from "./subscriptions.server";
import {
  CancelSubscriptionTypeVariables,
  GetSubscriptionsTypeVariables,
} from "./types";
import clientWrapper from "./wrappers/client.wrapper";

export const getSubscriptionsActions = () => {
  const getSubscriptions = (variables: GetSubscriptionsTypeVariables) => {
    return getSubscriptionsQuery(variables);
  };

  const cancelSubscription = (variables: CancelSubscriptionTypeVariables) => {
    return cancelSubscriptionMutation(variables);
  };

  return {
    getSubscriptions: clientWrapper(getSubscriptions),
    cancelSubscription: clientWrapper(cancelSubscription),
  };
};
