import {
  deleteAuthCookies,
  NON_AUTH_REST_ENDPOINTS,
  setAuthCookies,
} from "@/utils";
import { CookieValueTypes, getCookie } from "cookies-next";
import { refreshTokenRequest } from "../auth.server";

async function refreshToken(refreshToken: CookieValueTypes) {
  const { data } = await refreshTokenRequest(refreshToken as string);
  if (!data) return null;
  setAuthCookies(data);
  return data.accessToken;
}
const redirect = () => {
  deleteAuthCookies();
  window.location.reload();
};
export const sessionExpired =
  "Your session has expired. Please log in again to continue";

let isTokenRefreshing = false;

export const checkRequestUrl = (url: string) => {
  return NON_AUTH_REST_ENDPOINTS.some((str) => url.includes(str));
};

export const handleSessionExpired = async (isRetry?: boolean) => {
  const access_Token = getCookie("adminAccessToken");
  const refresh_Token = getCookie("adminRefreshToken");
  if (isTokenRefreshing || (!access_Token && isRetry) || access_Token)
    //retry the unauthorized request if (token is already refreshing, no token and is a retry request, a new token is available)
    return "retry";
  try {
    isTokenRefreshing = true;
    const newToken = await refreshToken(refresh_Token);
    isTokenRefreshing = false;
    if (newToken) return "success";
  } catch (error) {
    redirect();
  }
};
