import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import styled from "styled-components";

const StyledBox = styled(Box)`
  width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
`;

const CenteredModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the height of the container to 100% of the viewport height */
`;

const RemoveCommentsConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  username,
  id,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  username: string;
  id: string;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <CenteredModalContainer>
        <StyledBox>
          <Stack direction="column" alignItems="center" spacing={2}>
            <Typography variant="h5" color="black">
              Confirm remove all post comments for user {`${username}`}
            </Typography>
            <Typography variant="body1" color="black" mt={2}>
              Are you sure you want to remove <strong>ALL COMMENTS</strong> made by this user? id: {`${id}`}
            </Typography>
            <Typography variant="body1" color="red" mt={2}>
              Note: This is difficult to undo, so please double check the user and their comment history.
            </Typography>
            <Box mt={2}>
              <Button variant="contained" color="success" onClick={onConfirm}>
                Yes
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={onClose}
                sx={{ marginLeft: 2 }}
              >
                No
              </Button>
            </Box>
          </Stack>
        </StyledBox>
      </CenteredModalContainer>
    </Modal>
  );
};

export default RemoveCommentsConfirmationModal;
