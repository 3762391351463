import { Box, Stack } from "@mui/material";
import {
  Datagrid,
  DateField,
  List,
  Pagination,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

const LiveStreamsPagination = () => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
  </div>
);

const filters = [
  <TextInput key="1" label="title" source="title" alwaysOn />,
  <TextInput key="2" label="Livestream Id" source="id" alwaysOn />,
  <TextInput key="3" label="creatorId" source="creatorId" alwaysOn />,
  <SelectInput
    alwaysOn
    key="4"
    label="Is Live"
    source="isLive"
    choices={[
      { id: true, name: "Yes" },
      { id: undefined, name: "No" },
    ]}
  />,
  <SelectInput
    alwaysOn
    key="5"
    label="Audience Type"
    source="audienceType"
    choices={[
      { id: "PUBLIC", name: "Public" },
      { id: "SUBSCRIBER", name: "Subscribers" },
    ]}
  />,
];

export const LiveStreamsLists = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Stack direction="column" sx={{ height: "100vh", overflowY: "auto" }}>
        <List
          data-testid={"live-streams-list-ad"}
          filters={filters}
          pagination={<LiveStreamsPagination />}
        >
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField label="Live Stream ID" source="id" />
            <TextField label="Room ID" source="roomId" />
            <TextField label="Title" source="title" />
            <TextField label="Creator ID" source="creatorId" />
            <DateField label="Started At" source="startedAt" showTime />
            <DateField label="Ended At" source="endedAt" showTime />
          </Datagrid>
        </List>
      </Stack>
    </Box>
  );
};
