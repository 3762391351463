import { getSubscriptionsActions } from "@/server-actions";
import { convertTime } from "@/utils";
import { currencyFormatter } from "@fanfix/design-system";
import TocIcon from "@mui/icons-material/Toc";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AdminGetSubscriptions, Subscription } from "./type";
const Subscriptions = () => {
  const { userId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialPage = Number(query.get("page")) || 1;
  const isCreator = query.get("isCreator") === "false" ? false : true;

  const [page, setPage] = useState(initialPage);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const { getSubscriptions, cancelSubscription } = getSubscriptionsActions();
  const [list, setList] = useState<AdminGetSubscriptions>();
  const [item, setItem] = useState<Subscription>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ content: "" });

  const navigate = useNavigate();
  const fetchCreatorAdminSubscriptions = async () => {
    try {
      setLoading(true);
      const filter = isCreator ? { creatorId: userId } : { fanId: userId };
      const data = await getSubscriptions({
        input: {
          filter,
          pagination: { limit: 10, offset: (page - 1) * 10 },
        },
      });
      if (data.adminGetSubscriptions) {
        setList(data.adminGetSubscriptions);
      }
    } catch (err: any) {
      throw new Error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    handleCloseConfirm();
    if (item) {
      const input = isCreator
        ? { creatorId: userId as string, fanId: item.fanId as string }
        : { fanId: userId as string, creatorId: item.creatorId as string };
      try {
        const { adminCancelSubscription } = await cancelSubscription({ input });
        if (adminCancelSubscription.status === "canceled") {
          setList((prev) =>
            prev
              ? {
                  ...prev,
                  subscriptions: prev?.subscriptions.map((sub) =>
                    item.id === sub.id
                      ? { ...sub, status: "canceled", endDate: new Date() }
                      : sub
                  ),
                }
              : prev
          );
        } else {
          setErrorMessage({ content: "Failed to cancel subscription" });
        }
      } catch (e) {
        setErrorMessage({ content: "Failed to cancel subscription" });
        throw new Error(`Failed to cancel subscription: ${e}`);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    navigate(`?page=${value}&isCreator=${isCreator}`);
  };

  const handleCloseConfirm = () => {
    setConfirmIsOpen(false);
  };

  useEffect(() => {
    if (userId) {
      fetchCreatorAdminSubscriptions();
    }
  }, [userId, page]);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={10}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {list && list.totalCount > 0 ? (
        <Box
          sx={{
            maxHeight: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  {isCreator ? (
                    <TableCell>Fan</TableCell>
                  ) : (
                    <TableCell>Creator</TableCell>
                  )}
                  <TableCell>Months</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.subscriptions.map((subscription) => (
                  <TableRow key={subscription.id}>
                    <TableCell>{subscription.id}</TableCell>
                    {isCreator ? (
                      <TableCell>{subscription.fanId}</TableCell>
                    ) : (
                      <TableCell>{subscription.creatorId}</TableCell>
                    )}
                    <TableCell>{subscription.months}</TableCell>
                    <TableCell>
                      {currencyFormatter.formatCentsToDollar(
                        subscription.price
                      )}
                    </TableCell>
                    <TableCell>{subscription.status}</TableCell>
                    <TableCell>
                      {convertTime(subscription.createdAt, false, true)}
                    </TableCell>
                    <TableCell>
                      {subscription.endDate
                        ? convertTime(subscription.endDate, false, true)
                        : null}
                    </TableCell>
                    {/* <TableCell>
                      <Button
                        disabled={subscription.status === "canceled"}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setItem(subscription);
                          setConfirmIsOpen(true);
                        }}
                      >
                        cancel
                      </Button>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{ padding: "10px" }}
            page={page}
            count={Math.ceil(list?.totalCount / 10)}
            onChange={handleChange}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding={10}
          justifyContent="center"
          style={{ opacity: 0.5 }}
        >
          <TocIcon style={{ fontSize: "100px" }} />
          <Typography variant="h4">
            You don&apos;t have subscriptions yet
          </Typography>
        </Box>
      )}

      <Dialog open={confirmIsOpen} onClose={handleCloseConfirm}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this subscription ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm}>Cancel</Button>
          <Button onClick={handleCancel} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={Boolean(errorMessage.content)}
        autoHideDuration={10000}
        onClose={() => setErrorMessage({ content: "" })}
      >
        <Alert
          onClose={() => setErrorMessage({ content: "" })}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage.content}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Subscriptions;
