"use client";

import { FanFixException } from "@/utils";
import {
  checkRequestUrl,
  handleSessionExpired,
  sessionExpired,
} from "./refresh-token";

const reload = () => {
  window.location.reload();
};

function restClientWrapper<TData = any>(func: (input: TData) => Promise<any>) {
  return async function wrapped(input: TData, isRetry?: boolean) {
    const { success, error, data } = await func(input);
    if (error?.message === "Unauthorized" && checkRequestUrl(error.path)) {
      const res = await handleSessionExpired(isRetry);
      if (!res) throw new Error(sessionExpired);
      return wrapped(input, res === "retry");
    }

    if (!success && error)
      throw new FanFixException(error.message, {
        errors: error.errors,
        path: error.path,
        variables: error.variables,
      });
    return data as any;
  };
}

export default restClientWrapper;
