export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL as string;
export enum Roles {
  CREATOR = "Creator",
  FAN = "Fan",
}
export const COOKIE_EXPIRY_DAYS = 7 * 24 * 60 * 60 * 1000;

export const ACCESS_TOKEN_EXPIRY = 7 * 24 * 60 * 60 * 1000; // 15 * 60 * 1000;
export const REFRESH_TOKEN_EXPIRY = 30 * 24 * 60 * 60 * 1000;
export const UTM_COOKIE_EXPIRY_IN_MS = 1 * 60 * 60 * 24 * 1000;

export const NON_AUTH_REST_ENDPOINTS = ["check-username-available", "upload"];
