import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Typography } from "@fanfix/design-system";
import { PostList } from "../posts/PostList";
import { getPostsActions } from "@/server-actions";

export const RecommendedPostsLists = () => {
  const [posts, setPosts] = useState<any>([]);
  const [ids, setIds] = useState<string>("");
  const { getPosts, updatePosts } = getPostsActions();

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    const formattedIds = posts
      .map((post: any) => `https://app.fanfix.io/post/${post.id}`)
      .join("\n");
    setIds(formattedIds);
  }, [posts]);

  const fetchPosts = async () => {
    const result = await getPosts({
      input: {
        pagination: { limit: 100, offset: 0 },
        filter: { isRecommended: true },
      },
    });
    setPosts(result?.adminGetPosts.posts);
  };

  const setPostOrder = async () => {
    const initialIds = posts.map((post: any) => post.id);
    const newIds = ids
      .split("\n")
      .map((id) => id.split("/").pop()?.split("?")[0]);
    const removedIds = initialIds.filter((id: string) => !newIds.includes(id));

    const postOrder = newIds.map((id: string | undefined, index: number) => ({
      id: id,
      recommendedOrder: index,
    }));

    const removedPostOrder = removedIds.map((id: string, index: number) => ({
      id: id,
      recommendedOrder: null,
    }));

    const input = { posts: [...postOrder, ...removedPostOrder] };

    try {
      await updatePosts({ input });
      await fetchPosts();
      alert("Feed updated successfully");
    } catch (e) {
      console.error("ERROR: ", e);
      alert("Error updating feed");
    }
  };

  //   https://client-fan-dev.fanfix.dev/post/e537d643-69a9-47f8-aae3-9688247f4e4e

  return (
    <Stack spacing={1} mt={2} width={"70%"}>
      <Stack mb={2}>
        <Typography className="interSemibold16"> Instructions </Typography>
        <Typography className="interRegular12">
          {" "}
          • Recommended posts are shown in the text box and table below in the
          order in which they are rendered on the fan&apos;s feed{" "}
        </Typography>
        <Typography className="interRegular12">
          {" "}
          • To update the feed, add a url in the format:{" "}
          <b>
            https://app.fanfix.io/post/af29ecef-0b76-4fdd-89ba-6113ec0a0601
          </b>{" "}
        </Typography>
        <Typography className="interRegular12">
          {" "}
          • Remove the post by deleting the url from text box
        </Typography>
        <Typography className="interRegular12">
          {" "}
          • As a guideline: posts in this feed should be <b>FREE TO EVERYONE</b>
        </Typography>
      </Stack>
      <TextField
        data-testid={"recommended-post-text-field"}
        label="Recommended Posts"
        multiline={true}
        value={ids}
        rows={10}
        onChange={(e) => setIds(e.target.value)}
      />
      <Button
        sx={{ marginBottom: 2 }}
        variant="contained"
        onClick={setPostOrder}
      >
        Submit Feed
      </Button>
      <PostList />
    </Stack>
  );
};

interface PostOrder {
  input: { id: string; recommendedOrder: number }[];
}
