"use client";

import { FanFixException } from "@/utils";
import { OperationVariables } from "@apollo/client";
import { handleSessionExpired, sessionExpired } from "./refresh-token";

const reload = () => {
  window.location.reload();
};

function clientWrapper<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  func: (options: TVariables) => Promise<{
    success: boolean;
    data?: TData | null;
    error?: {
      message: string;
      path?: string[];
      errors?: any;
      variables?: OperationVariables;
    };
  }>
) {
  return async function wrapped(options: TVariables, isRetry?: boolean) {
    const { success, error, data } = await func(options);

    if (error?.message === "Unauthorized") {
      const res = await handleSessionExpired(isRetry);
      if (!res) throw new Error(sessionExpired);
      return wrapped(options, res === "retry");
    }

    if (!success && error)
      throw new FanFixException(error.message, {
        errors: error.errors,
        path: error.path?.[0],
        variables: error.variables,
      });
    return data!;
  };
}

export default clientWrapper;
